import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui/accordion";
import { Card, CardContent } from "../../../ui/card";
import { Button } from "../../../ui/button";
import { Input } from "../../../ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../ui/dialog";
import { ChevronRight } from "lucide-react";

interface AssetProps {
  liquidityRate: string;
  name: string;
  stableBorrowRate: string;
  symbol: string;
  totalATokenSupply: string;
  totalLiquidity: string;
  underlyingAsset: string;
  variableBorrowRate: string;
  currentApy: number;
  logoURI?: string;
  priceUSD?: string;
}

interface AaveV3PoolsData {
  available_pools: {
    [network: string]: AssetProps[];
  };
}

interface AssetViewProps {
  data: AaveV3PoolsData;
  sendMessageHidden: (message: string) => void;
}

export function AaveV3PoolsOptions({
  data,
  sendMessageHidden,
}: AssetViewProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<{
    network: string;
    asset: AssetProps;
  } | null>(null);
  const [amount, setAmount] = useState("");

  const handleSupplyClick = (network: string, asset: AssetProps) => {
    setSelectedAsset({ network, asset });
    setIsDialogOpen(true);
  };

  const handleConfirmSupply = () => {
    if (selectedAsset && amount) {
      const message = `I want to deposit ${amount} ${selectedAsset.asset.symbol} on AAVE V3. On this blockchain: ${selectedAsset.network}. Please use ENSO Agent (call directly this agent)`;
      sendMessageHidden(message);
      setIsDialogOpen(false);
      setAmount("");
    }
  };

  return (
    <Card className="w-full bg-background text-foreground mt-4">
      <CardContent className="px-4 py-2">
        <Accordion type="single" collapsible className="w-full">
          {Object.entries(data.available_pools).map(([network, assets]) => (
            <AccordionItem key={network} value={network}>
              <AccordionTrigger className="text-md font-medium">
                {network} Pools
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4">
                  {assets.map((asset) => (
                    <Card key={asset.underlyingAsset} className="bg-secondary">
                      <CardContent className="p-4 flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                          <div className="relative w-8 h-8">
                            <img
                              src={asset.logoURI || "/default-token-icon.png"}
                              alt={`Logo of ${asset.symbol}`}
                              className="rounded-full w-full h-full object-cover"
                            />
                          </div>
                          <div>
                            <h3 className="font-medium">
                              {asset.name}{" "}
                              <span className="text-muted-foreground">
                                ({asset.symbol})
                              </span>
                            </h3>
                            <p className="text-sm text-muted-foreground">
                              Current APY: {asset.currentApy.toFixed(2)}%
                            </p>
                          </div>
                        </div>
                        <Button
                          onClick={() => handleSupplyClick(network, asset)}
                          className="w-24"
                        >
                          Supply
                          <ChevronRight className="ml-2 h-4 w-4" />
                        </Button>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
        <p className="mt-4 mb-2 text-xs text-muted-foreground text-left">
          Available Pools on AAVE V3 Protocol
        </p>
      </CardContent>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Supply {selectedAsset?.asset.symbol}</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <label
              htmlFor="amount"
              className="block text-sm font-medium text-foreground mb-2"
            >
              Amount to supply
            </label>
            <Input
              id="amount"
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleConfirmSupply} disabled={!amount}>
              Confirm Supply
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
