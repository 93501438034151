import axios from "axios";
import { createContext, useContext, useState } from "react";
import * as siwe from "siwe";
import { WalletProvider, WalletSignerContextProvider } from "./wallet-provider";
import { AssembledTransaction, EvmTransactionData } from "../use-wallets";
import { ethers } from "ethers";
import {
  signEvmHash,
  signEvmMessage,
  signEvmTransactionWithRetry,
  waitForEVMTransaction,
} from "../../../utils/wallets";
import { serverApi } from "../../../services/server";
import {
  getBalances,
  updateBalancesAfterTransaction,
  setChatHistory,
  removeChatHistory,
} from "../../../redux/slices/user";
import { getExistingToken } from "../../../utils/firebase_cloud_messaging";
import { useAppDispatch } from "../../../redux/hooks";

export interface AuthSig {
  sig: string;
  derivedVia: "web3.eth.personal.sign";
  signedMessage: string;
  address: string;
}

export interface Wallet {
  address: string;
  chains: string[];
  type: string;
  privateKey: string;
}

const LIT_SERVICE_URL = process.env.REACT_APP_LIT_SERVICE_URL;
const litService = axios.create({
  baseURL: LIT_SERVICE_URL,
});

export interface LitWalletSignerContextProvider
  extends WalletSignerContextProvider {
  wallets: {
    address: string;
    privateKey: string;
    type: string;
  }[];
  agent_address: string | null;
}

const EvmWalletContext = createContext<
  LitWalletSignerContextProvider | undefined
>(undefined);

export const useEvmWallets = () => {
  const context = useContext(EvmWalletContext);
  if (!context) {
    throw new Error("useLitWallets must be used within a LitWalletContext");
  }
  return context;
};

export const EvmWalletProvider = ({ children }: { children: any }) => {
  const [authSig, setAuthSig] = useState<AuthSig | null>(null);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [address, setAddress] = useState<string | null>(null);
  const [agentsPreference, setAgentsPreference] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  async function connect(provider: WalletProvider): Promise<void> {
    const { ethereum } = window as any;
    if (!ethereum) {
      throw new Error("Please install MetaMask!");
    }

    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    const address = ethers.getAddress(accounts[0]);

    const token = await getExistingToken();

    if (token) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await serverApi.save_fcm_user_token(address, token, timezone);
    }

    try {
      // Initialize LitNodeClient
      const nonce = (await litService.get("/lit/nonce")).data.nonce;

      // Craft the SIWE message
      const domain = window.location.host ?? "localhost:3000";
      const origin = window.location.href ?? "http://localhost";
      const statement = "Login to access your executor model wallets";

      // expiration time in ISO 8601 format.  This is 7 days in the future, calculated in milliseconds
      const expirationTime = new Date(
        Date.now() + 1000 * 60 * 60 * 24 * 7
      ).toISOString();

      const siweMessage = new siwe.SiweMessage({
        domain,
        address,
        statement,
        uri: origin,
        version: "1",
        chainId: 1,
        nonce: nonce,
        expirationTime,
      });
      const messageToSign = siweMessage.prepareMessage();

      // Sign the message and format the authSig
      const signature = await (window as any).ethereum.request({
        method: "personal_sign",
        params: [messageToSign, address],
      });
      const authSig: AuthSig = {
        sig: signature,
        derivedVia: "web3.eth.personal.sign",
        signedMessage: messageToSign,
        address: address,
      };
      setAuthSig(authSig);

      // fetch wallets
      let evmWalletAddress;
      try {
        const wallets = await getWallets(authSig);
        evmWalletAddress = wallets.find((w) => w.type === "evm")?.address;

        if (!evmWalletAddress) {
          throw new Error("No EVM wallet found");
        }
      } catch (error) {
        console.error("Error getting wallets:", error);
        evmWalletAddress = undefined;
      }

      // Fetch balances concurrently without blocking
      // Balances will be displayed when the fetching finishes
      serverApi
        .getBalances(address, null, null, null, null)
        .then((EOAWalletBalances) => {
          dispatch(getBalances(EOAWalletBalances));
        });
      if (evmWalletAddress) {
        serverApi
          .getBalances(evmWalletAddress, null, null, null, null)
          .then((LitWalletBalances) => {
            dispatch(getBalances(LitWalletBalances));
          });
      }

      const get_chat_history_response =
        await serverApi.getChatsHistory(address);
      const chats = get_chat_history_response.data.chats_history;

      dispatch(setChatHistory(chats));
    } catch (e) {
      console.error(e);
    }

    setAddress(address);
  }

  async function disconnect() {
    if (address) {
      dispatch(removeChatHistory(address));
    }
    setAuthSig(null);
    setWallets([]);
    setAddress(null);
  }

  async function getWallets(authSig: AuthSig): Promise<Wallet[]> {
    const wallets = await litService.post("/wallets", { authSig });
    setWallets(wallets.data.wallets);
    return wallets.data.wallets;
  }

  async function sendTransaction(
    tx: AssembledTransaction,
    isLastTransaction: boolean
  ): Promise<string> {
    if (agentsPreference) return "Managed by agents";

    const txData = tx as EvmTransactionData;

    // switch to the correct chain
    const provider = (window as any).ethereum;
    const chainIdHex = String(txData.chainId).startsWith("0x")
      ? txData.chainId
      : `0x${Number(txData.chainId).toString(16)}`;
    const currentChainId = await provider.request({ method: "eth_chainId" });
    if (currentChainId !== chainIdHex) {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }],
      });
    }

    // Check if we have a gas station and send gas if needed
    // Added a try-catch so we don't block the transaction if the gas station is not available or it fails
    try {
      await serverApi.check_and_send_gas_from_gas_station(
        chainIdHex as string,
        txData.from
      );
    } catch (error) {
      console.error(`Error on gas station endpoint call: ${error}`);
    }

    // wait for the transaction to be confirmed
    const txResponse = await signEvmTransactionWithRetry(tx);
    await waitForEVMTransaction(txResponse.hash.toString(), txData.chainId);

    const evmWalletAddress = agentsPreference
      ? wallets.find((w) => w.type === "evm")?.address!
      : address;

    if (isLastTransaction) {
      const response = await serverApi.getBalances(
        evmWalletAddress ?? null,
        null,
        null,
        null,
        null
      );
      dispatch(updateBalancesAfterTransaction(response));
    }
    return txResponse.hash.toString();
  }

  async function signMessage(message: string): Promise<string> {
    const signature = await signEvmMessage(message);
    return signature;
  }

  async function signTypedMessage(message: string): Promise<string> {
    const signature = await signEvmHash(message);
    return signature;
  }

  return (
    <EvmWalletContext.Provider
      value={{
        authSig: authSig,
        address: agentsPreference
          ? wallets.find((w) => w.type === "evm")?.address!
          : address,
        agent_address: !agentsPreference
          ? wallets.find((w) => w.type === "evm")?.address!
          : address,
        connect,
        disconnect,
        signAndSendTransaction: sendTransaction,
        signMessage,
        wallets,
        agentsPreference,
        setAgentsPreference,
        signTypedMessage,
      }}
    >
      {children}
    </EvmWalletContext.Provider>
  );
};
