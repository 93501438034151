import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import { serverApi } from "../services/server";

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export async function requestFCMPermission(wallet_address: string) {
  try {
    const existingToken = await getExistingToken();
    if (existingToken) {
      return;
    }
  } catch (error) {
    console.error("Error checking existing token: ", error);
    return;
  }

  const permission = await requestNotificationPermission();

  if (permission === "granted") {
    try {
      const token = await getExistingToken();
      if (token) {
        await saveToken(wallet_address, token);
      }
    } catch (error) {
      console.error("Error getting token: ", error);
    }
  } else {
    console.log("User dismissed or blocked the permission prompt");
  }
}

export async function getExistingToken() {
  try {
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID as string,
    });
  } catch (e: any) {
    console.error(e)
    return null;
  }
}

async function requestNotificationPermission() {
  return await Notification.requestPermission();
}

async function saveToken(wallet_address: string, token: string) {
  try {
    await serverApi.save_fcm_user_token(
      wallet_address,
      token,
      getUserTimezone()
    );
    console.log("Token saved successfully");
  } catch (error) {
    console.error("Error saving token: ", error);
  }
}
