import {
  Home,
  Users,
  FileText,
  Settings,
  History,
  MessageCircle,
  Trash,
} from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "../components/ui/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { ModeToggle } from "./toggle-theme";
import { images } from "../assets/images";
import { useTheme } from "./theme-provider";
import SettingsPage from "../screens/settings";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { useChat, Message } from "../hooks/use-chat";
import { setCurrentChatId, deleteChat } from "../redux/slices/user";
import { serverApi } from "../services/server";

interface ChatHistoryMessages {
  messages: Message[];
  lastUpdatedAt: string;
}

export default function SideBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();
  const { preferredAgent } = useAppSelector((state) => state.gpt);
  const { chatHistory, currentChatId } = useAppSelector((state) => state.user);
  const { setMessages, restoreOrSetNewChat, loading } = useChat();

  // Determine the active tab based on the current path
  const getActiveTab = () => {
    if (location.pathname === "/") return "Home";
    if (location.pathname === "/agents") return "Agents";
    if (location.pathname === "/chat") return "Chat";
    if (location.pathname === "/chat-history") return "Chat History";
    return "";
  };

  const activeTab = getActiveTab();

  const restoreChat = (
    ChatHistoryMessages: ChatHistoryMessages,
    chatId: string
  ) => {
    setMessages([]);
    const chatMessages = ChatHistoryMessages.messages;
    chatMessages?.forEach((message) => {
      if (message.component !== null) {
        setMessages((m: Message[]) => [
          ...m,
          {
            agent: "ui",
            message: "",
            component: message.component,
            data: message.data,
          },
        ]);
      } else if (message.agent === "human") {
        setMessages((m: string) => [
          ...m,
          { agent: "human", message: message.message },
        ]);
      } else if (message.message !== "") {
        setMessages((m: string) => [
          ...m,
          { agent: "ai", message: message.message },
        ]);
      }
    });

    // Get the last 5 messages to avoid exceeding autogen tokens limit
    const lastFiveMessages = chatMessages?.slice(-5);
    const stringifiedMessages = JSON.stringify(lastFiveMessages);

    restoreOrSetNewChat(chatId, stringifiedMessages);
    dispatch(setCurrentChatId(chatId));
    navigate("/chat");
  };

  const handleDeleteChat = async (chatId: string, walletAddress: string) => {
    try {
      dispatch(deleteChat(chatId));
      if (currentChatId === chatId) {
        // If its the current chat, clear the messages and reset the current chat id
        setMessages([]);
        dispatch(setCurrentChatId(""));
        restoreOrSetNewChat(null, null);
      }
      await serverApi.deleteChatFromHistory(chatId, walletAddress);
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  return (
    <Sidebar className="border-r" collapsible="offcanvas">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <p
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => navigate("/")}
              >
                <img
                  src={
                    theme === "light"
                      ? images.DefaultAgentLogo
                      : images.SphereLogoWhite
                  }
                  alt="SphereOne"
                  className="h-6 w-6"
                />
                <span className="text-xl font-bold">SphereOne</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent className="flex flex-col justify-between">
        <SidebarMenu className="flex flex-col gap-2">
          {/* Home */}
          <SidebarMenuItem>
            <SidebarMenuButton
              disabled={loading}
              asChild
              className={`transition-all transform duration-300 ease-in-out ${activeTab === "Home" ? "mx-2" : ""}`}
            >
              <p
                className={`flex items-center gap-2 cursor-pointer ${
                  activeTab === "Home" ? "bg-gray-200 dark:bg-gray-800" : ""
                }`}
                onClick={() => navigate("/")}
              >
                <Home className="h-4 w-4" />
                <span>Home</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>

          {/* Agents */}
          <SidebarMenuItem>
            <SidebarMenuButton
              disabled={loading}
              asChild
              className={`transition-all transform duration-300 ease-in-out ${activeTab === "Agents" ? "mx-2" : ""}`}
            >
              <p
                className={`flex items-center gap-2 cursor-pointer ${
                  activeTab === "Agents" ? "bg-gray-200 dark:bg-gray-800" : ""
                }`}
                onClick={() => navigate("/agents")}
              >
                <Users className="h-4 w-4" />
                <span>Agents</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>

          {/* Chat */}
          {preferredAgent && (
            <SidebarMenuItem>
              <SidebarMenuButton
                disabled={loading}
                asChild
                className={`transition-all transform duration-300 ease-in-out ${activeTab === "Chat" ? "mx-2" : ""}`}
              >
                <p
                  className={`flex items-center gap-2 cursor-pointer ${
                    activeTab === "Chat" ? "bg-gray-200 dark:bg-gray-800" : ""
                  }`}
                  onClick={() => navigate("/chat")}
                >
                  <MessageCircle className="h-4 w-4" />
                  <span>Chat</span>
                </p>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}

          {/* Chat History */}
          <SidebarMenuItem>
            <SidebarMenuButton
              disabled={loading}
              asChild
              className={`transition-all transform duration-300 ease-in-out ${activeTab === "Chat History" ? "mx-2" : ""}`}
            >
              <p
                className={`flex items-center gap-2 cursor-pointer ${
                  activeTab === "Chat History"
                    ? "bg-gray-200 dark:bg-gray-800"
                    : ""
                }`}
              >
                <History className="h-4 w-4" />
                <span>Chat History</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>

          {/* List of Chat History */}
          {chatHistory &&
            Object.entries(chatHistory).map(([chatId, messages]) => (
              <SidebarMenuItem
                key={chatId}
                className={`flex flex-row items-center justify-between px-2 rounded-md transition-all
        ${currentChatId === chatId ? "bg-gray-200 dark:bg-gray-800" : "hover:bg-gray-200 dark:hover:bg-gray-700"}
      `}
              >
                <SidebarMenuButton
                  className="hover:bg-transparent"
                  asChild
                  onClick={() =>
                    !loading &&
                    restoreChat(messages as ChatHistoryMessages, chatId)
                  }
                  disabled={loading}
                >
                  <p
                    className={`flex items-center gap-2 italic truncate text-gray-700 dark:text-gray-300 transition-opacity
            ${loading ? "opacity-50 cursor-not-allowed" : "hover:opacity-80"}
          `}
                  >
                    <span>{messages.messages[0].message.slice(0, 30)}...</span>
                  </p>
                </SidebarMenuButton>
                <button
                  disabled={loading}
                  onClick={() =>
                    handleDeleteChat(chatId, messages.associated_wallet!)
                  }
                  className="p-1 rounded-full hover:bg-red-100 dark:hover:bg-red-800 transition-all"
                >
                  <Trash className="h-4 w-4 text-gray-400 dark:text-gray-500 hover:text-red-600 dark:hover:text-red-400" />
                </button>
              </SidebarMenuItem>
            ))}
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <ModeToggle />
            </SidebarMenuButton>
          </SidebarMenuItem>

          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <p
                className={`flex items-center gap-2 cursor-pointer`}
                onClick={() =>
                  window.open(
                    "https://sphereone.notion.site/Surface-Area-d4883498ae5f41909e7fae69795645ad",
                    "_blank"
                  )
                }
              >
                <FileText className="h-4 w-4" />
                <span>Docs</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>

          <Dialog>
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <DialogTrigger>
                  <p className={`flex items-center gap-2 cursor-pointer`}>
                    <Settings className="h-4 w-4" />
                    <span>Settings</span>
                  </p>
                </DialogTrigger>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <DialogContent className="max-w-lg mx-auto h-[90vh]">
              <SettingsPage />
            </DialogContent>
          </Dialog>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
}
