import { useNavigate, useRouteError } from "react-router-dom";
import { Button } from "../components/ui/button";

function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center space-y-3">
      <p className="text-4xl">Oops!</p>
      <p className="text-2xl">Sorry, an unexpected error has occurred.</p>
      <p className="text-xl">
        <i className="text-gray-500">{error.statusText || error.message}</i>
      </p>
      <Button onClick={() => navigate("/")}>Go back</Button>
    </div>
  );
}

export default ErrorPage;
