import { useEffect, useState } from "react";
import { FaChevronLeft, FaExclamationCircle } from "react-icons/fa";
import { TagIcon, UserIcon } from "@heroicons/react/24/outline";

import { images } from "../../assets/images";
import { useChat } from "../../hooks/use-chat";
import { AgentProps } from "../agent-functionality";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { XMarkIcon } from "@heroicons/react/20/solid";
import HorizontalSlider from "./message-actions/QuickActionSlider";
import {
  ArrowRightEndOnRectangleIcon,
  ArrowsRightLeftIcon,
  ArrowTrendingUpIcon,
  ChartPieIcon,
  InboxArrowDownIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import {
  CalendarCheck,
  HandCoins,
  Landmark,
  ScaleIcon,
  Waypoints,
} from "lucide-react";
import { FilterDialogButton } from "../themed/filter-dialog";
import { ThemedButton } from "../themed/ThemedButton";
import SearchBarOverlay from "../search-bar-overlay";
import { useNavigate } from "react-router-dom";
import { selectPreferredAgentThunk } from "../../redux/slices/gptThunk";

export default function ChatEmptyScreen(props: {
  setInput: (str: string) => void;
  chatStarted: boolean;
}) {
  const {
    availableAgents: agents,
    superAgent,
    aiPaymentAgent,
    allChains,
  } = useChat();

  const { preferredAgent } = useAppSelector((state) => state.gpt);
  
  const [filteredAgents, setFilteredAgents] = useState<AgentProps[]>(agents);
  const [filters, setFilters] = useState<{
    action: string;
    chains: string[];
    prompt?: string;
  }>({
    action: "ALL",
    chains: [],
    prompt: undefined,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function setSelectedAgent(agent: AgentProps | null) {
    await dispatch(selectPreferredAgentThunk(agent)).unwrap();
  }

  useEffect(() => {
    let currentAgents: AgentProps[] = agents;
    if (filters.chains.length > 0) {
      currentAgents = currentAgents.filter((agent) =>
        filters.chains.some((chain) => agent.supportedChains.includes(chain))
      );
    }
    if (filters.action && filters.action !== "ALL") {
      currentAgents = currentAgents.filter(
        (agent) => agent.action === filters.action
      );
    }
    if (filters.prompt) {
      currentAgents = currentAgents.filter(
        (agent) =>
          agent.action.toLowerCase().includes(filters.prompt!.toLowerCase()) ||
          agent.name.toLowerCase().includes(filters.prompt!.toLowerCase()) ||
          agent.parent?.toLowerCase().includes(filters.prompt!.toLowerCase())
      );
    }
    setFilteredAgents(currentAgents);
  }, [filters, agents]);
  // endregion

  return (
    <div className="max-w-5xl mx-auto p-4 text-center -z-50 flex flex-col h-full">
      <div className="h-full">
        {preferredAgent === null ? (
          <>
            <img
              src={images.SphereLogo}
              alt="Sphere logo"
              className="h-20 w-20 rounded-full mx-auto mb-2"
            />
            <h1 className="text-2xl text-gray-400 dark:text-gray-300 p-2">
              Explore agents
            </h1>

            <div className="container mx-auto mt-2 py-4">
              <h1 className="text-2xl font-bold">Quick Actions</h1>
              <HorizontalSlider />
            </div>
          </>
        ) : (
          <>
            <img
              src={preferredAgent.img ?? images.DefaultAgentLogo}
              alt="Sphere logo"
              className="h-20 w-20 rounded-full mx-auto mb-2"
            />
            <h1 className="text-4xl text-gray-400 dark:text-gray-300 p-2">
              {preferredAgent.name}
            </h1>
          </>
        )}

        {preferredAgent ? (
          <ThemedButton
            className="h-14 w-auto rounded hover:bg-gray-400 bg-gray-500 dark:hover:bg-gray-800 dark:bg-gray-700 p-4 flex items-center justify-center gap-2"
            onClick={() => {
              setSelectedAgent(null);
            }}
          >
            <FaChevronLeft /> Back
          </ThemedButton>
        ) : (
          <div className="flex flex-col gap-y-3 mb-2">
            <div className="flex flex-row justify-center items-center w-4/5 mt-2 gap-2 mx-auto">
              <SearchBarOverlay />
              <FilterDialogButton
                allChains={allChains}
                onApplyFilter={({
                  selectedAction,
                  selectedChains,
                }: {
                  selectedAction: string;
                  selectedChains: string[];
                }) => {
                  setFilters((prev) => ({
                    ...prev,
                    action: selectedAction,
                    chains: selectedChains,
                  }));
                }}
                filtersApplied={
                  filters.chains.length + (filters.action !== "ALL" ? 1 : 0)
                }
              />
            </div>
            <div className="flex flex-wrap gap-x-2 gap-y-1">
              {filters.action !== "ALL" && (
                <div
                  className="flex bg-gray-700 hover:bg-gray-500 text-white rounded-lg p-2 cursor-pointer"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, action: "ALL" }))
                  }
                >
                  <p className="mr-2 text-sm">{filters.action}</p>
                  <XMarkIcon className="h-5 w-auto" />
                </div>
              )}
              {filters.chains.length > 0 &&
                filters.chains.map((chain) => (
                  <div
                    className="flex bg-gray-700 hover:bg-gray-500 text-white rounded-lg p-2 cursor-pointer"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        chains: prev.chains.filter(
                          (filterChain: string) => filterChain !== chain
                        ),
                      }))
                    }
                  >
                    <p className="mr-2 text-sm">{chain}</p>
                    <XMarkIcon className="h-5 w-auto" />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      {!preferredAgent && (
        <div className="flex mt-3 flex-col gap-y-1 h-full">
          {superAgent && (
            <div
              className="flex flex-row items-center bg-gray-200 dark:bg-gray-700 border-2 rounded-lg p-6 text-foreground cursor-pointer"
              onClick={() => {
                setSelectedAgent(superAgent);
              }}
            >
              <img
                className="h-24 w-auto rounded-full"
                src={images.SphereLogo}
                alt={superAgent.name}
              />
              <div className="pl-6 text-left">
                <h1 className="font-bold text-2xl">{superAgent.name}</h1>
                <h4 className="text-gray-500 dark:text-gray-300 text-base">
                  {superAgent.description}
                </h4>
              </div>
            </div>
          )}
          {aiPaymentAgent && (
            <div
              className="flex flex-row items-center bg-gray-200 dark:bg-gray-700 border-2 rounded-lg p-6 text-foreground cursor-pointer"
              onClick={() => {
                setSelectedAgent(aiPaymentAgent);
              }}
            >
              <img
                className="h-24 w-auto rounded-full"
                src={images.SphereLogo}
                alt={aiPaymentAgent.name}
              />
              <div className="pl-6 text-left">
                <h1 className="font-bold text-2xl">{aiPaymentAgent.name}</h1>
                <h4 className="text-gray-500 dark:text-gray-300 text-base">
                  {aiPaymentAgent.description}
                </h4>
              </div>
            </div>
          )}
          <div className="flex flex-grow relative h-full">
            <div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-b from-background to-transparent z-5" />
            <div className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-t from-background to-transparent z-5" />
            <AgentsGrid
              agents={filteredAgents}
              setSelectedAgent={setSelectedAgent}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export const AgentsGrid = ({
  agents,
  setSelectedAgent,
}: {
  agents: AgentProps[];
  setSelectedAgent: any;
}) => {
  return (
    <div className="py-4 flex justify-center gap-2 overflow-auto h-full w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-min w-full gap-6">
        {agents.length > 0 ? (
          agents.map((agent: AgentProps, index: number) => {
            const ActionIcon = AgentSupportedOperations[agent.action] || null;
            return (
              <div
                key={index}
                className="transition-all duration-200 cursor-pointer bg-gray-200 dark:bg-gray-700 rounded-lg p-4 w-full flex items-start space-x-4"
                onClick={() => {
                  setSelectedAgent(agent);
                }}
              >
                <div className="w-24 h-24 rounded-lg p-2 bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <img
                    className="h-20 w-20 object-contain"
                    src={agent.img ? agent.img : images.DefaultAgentLogo}
                    alt={agent.name}
                    loading={"lazy"}
                  />
                </div>
                <div className="p-4 text-left flex-1">
                  <h1 className="font-semibold text-lg flex flex-row gap-x-4 items-center mb-2">
                    {agent.name}
                    {ActionIcon}
                  </h1>
                  <div className="flex flex-row gap-x-6">
                    <p className="text-sm text-gray-400 flex items-center">
                      <TagIcon className="h-4 w-4 mr-2" />
                      <span className="font-medium text-foreground">
                        {agent.action}
                      </span>
                    </p>
                    {agent.parent && (
                      <p className="text-sm text-gray-400 flex items-center">
                        <UserIcon className="h-4 w-4 mr-2" />
                        <span className="font-medium text-foreground">
                          {agent.parent}
                        </span>
                      </p>
                    )}
                  </div>
                  <p className="text-sm text-gray-500 dark:text-gray-300 mt-2 line-clamp-2 hover:line-clamp-none">
                    {agent.description || getDefaultDescription(agent.action)}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="transition-all duration-200 bg-gray-700 items-center flex flex-1 rounded-lg p-4 space-x-2">
            <FaExclamationCircle className="h-10 w-auto text-yellow-500" />
            <p className="text-lg font-medium">
              No matches. Please try a different search.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const AgentSupportedOperations = {
  ONRAMP: <Landmark className="h-7 w-auto rounded-full bg-gray-600 p-1" />,
  STAKING: <HandCoins className="h-7 w-auto rounded-full bg-gray-600 p-1" />,
  PERPS: (
    <ArrowTrendingUpIcon className="h-7 w-auto rounded-full bg-gray-600 p-1" />
  ),
  SWAP: (
    <ArrowsRightLeftIcon className="h-7 w-auto rounded-full bg-gray-600 p-1" />
  ),
  BRIDGE: <Waypoints className="h-7 w-auto rounded-full bg-gray-600 p-1" />,
  BALANCE: <ScaleIcon className="h-7 w-auto rounded-full bg-gray-600 p-1" />,
  TRANSFER: (
    <ArrowRightEndOnRectangleIcon className="h-7 w-auto rounded-full bg-gray-600 p-1" />
  ),
  DEX: <ChartPieIcon className="h-7 w-auto rounded-full bg-gray-600 p-1" />,
  DEPOSIT: (
    <InboxArrowDownIcon className="h-7 w-auto rounded-full bg-gray-600 p-1" />
  ),
  NFT: <PhotoIcon className="h-7 w-auto rounded-full bg-gray-600 p-1" />,
  SCHEDULE: (
    <CalendarCheck className="h-7 w-auto rounded-full bg-gray-600 p-1" />
  ),
};

export const getDefaultDescription = (action: string): string => {
  const descriptions = {
    ONRAMP:
      "You can use this agent to convert fiat currency to cryptocurrency.",
    STAKING:
      "This agent helps you stake your cryptocurrencies to earn rewards.",
    PERPS: "Use this agent for perpetual futures trading operations.",
    SWAP: "This agent allows you to exchange one cryptocurrency for another.",
    BRIDGE:
      "You can use this agent to perform cross-chain swaps (bridge operations).",
    BALANCE:
      "Check your cryptocurrency balances across different chains with this agent.",
    TRANSFER:
      "This agent helps you transfer cryptocurrencies between wallets or accounts.",
    DEX: "Use this agent to interact with decentralized exchanges (DEXs).",
    DEPOSIT:
      "This agent assists with depositing cryptocurrencies into various platforms.",
    NFT: "Explore and interact with Non-Fungible Tokens (NFTs) using this agent.",
    SCHEDULE: "Set up scheduled cryptocurrency operations with this agent.",
  };

  return (
    descriptions[action as keyof typeof descriptions] ||
    "This agent can help you with various cryptocurrency operations."
  );
};
