import { AssembledTransaction } from "../use-wallets";
import { AuthSig } from "./evm-provider";

export enum WalletProvider {
  EVM = "metamask",
  AGENT = "agent",
  PHANTOM = "phantom",
  TIPLINK = "tiplink",
  SUI = "sui",
  COSMOS = "cosmos",
  APTOS = "aptos",
  BITCOIN = "bitcoin",
}

export const walletProviderLabels = {
  [WalletProvider.EVM]: "Evm",
  [WalletProvider.AGENT]: "Agent",
  [WalletProvider.PHANTOM]: "Phantom",
  [WalletProvider.TIPLINK]: "Tiplink",
  [WalletProvider.SUI]: "Sui",
  [WalletProvider.COSMOS]: "Cosmos",
  [WalletProvider.APTOS]: "Aptos",
  [WalletProvider.BITCOIN]: "Bitcoin",
};

export interface WalletSignerContextProvider {
  address: string | null;
  signAndSendTransaction: (
    transaction: AssembledTransaction,
    isLastTransactoin: boolean
  ) => Promise<string> | string;
  connect: (provider: WalletProvider) => Promise<void> | void;
  signMessage: (message: string) => Promise<string | Uint8Array> | void;
  signTypedMessage?: (message: string) => Promise<string | Uint8Array>;
  disconnect: () => Promise<void>;
  authSig: AuthSig | null;
  agentsPreference: boolean;
  setAgentsPreference: (value: boolean) => void;
}
