import { useState } from "react";
import {
  InfoIcon,
  SlippageIcon,
  SwapIcon,
  SwapRoute,
  PercentIcon,
} from "../../../assets/icons/flowbite-icons";
import { parseAmount } from "../../../utils/wallets";
import { Message, Submit } from "../../../hooks/use-chat";
import { useAppDispatch } from "../../../redux/hooks";
import { updateTokenConfetti } from "../../../redux/slices/user";
import { images } from "../../../assets/images";
import { useWallets } from "../../../hooks/wallets/use-wallets";

export const SwapQuote = ({
  data,
  setMessages,
  chatId,
  accessToken,
  active,
  onDone,
  submit,
}: {
  data: any;
  chatId: string;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  accessToken: string;
  active: boolean;
  onDone: () => void;
  submit: (data: Submit) => void;
}) => {
  const [signature, setSignature] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { addresses, signTransactions } = useWallets();
  const dispatch = useAppDispatch();

  const onSwap = async () => {
    if (signature) return;
    try {
      setLoading(true);
      const signedTransaction = await signTransactions(data.transaction);
      const signedSignature =
        signedTransaction.hashes[signedTransaction.hashes.length - 1];
      setSignature(signedSignature);
      setLoading(false);
      onDone();
      submit({
        transaction: data,
        signature: signedSignature,
        walletAddress: addresses.SOLANA!,
        protocol_name: data.protocol_name,
        bridge_id: data.bridge_id ?? "",
      });

      dispatch(updateTokenConfetti(data.to_token.logoURI || images.SphereLogo));
    } catch (e: any) {
      console.error(e);
      setLoading(false);
      setMessages((m: Message[]) => [
        ...m,
        {
          agent: "ui",
          message: "",
          data: {
            status: "error",
            content: e.response?.data?.error ?? e.message,
          },
          component: "tx_status",
        },
      ]);
    } finally {
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <div className="bg-gray-800 border border-gray-700 shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4 min-w-full sm:min-w-[450px]">
        <div className="bg-gray-700 border border-gray-600 rounded-lg p-3 flex justify-between items-center gap-4 w-full h-70px">
          {/* Input Token */}
          <div className="flex flex-row items-start gap-2">
            <img
              src={data.from_token.logoURI}
              alt={data.from_token.symbol}
              className="w-6 h-6 rounded-full"
            />
            <div className="flex flex-col justify-center gap-1">
              <span className="text-white font-medium text-sm leading-6">
                {parseAmount(data.from_amount, data?.from_token.decimals)}{" "}
                {data?.from_token.symbol}
              </span>
              <span className="text-gray-400 text-sm leading-6">
                ${Number(data?.from_token.usd_amount)}
              </span>
            </div>
          </div>
          <div>
            <SwapIcon />
          </div>
          {/* Output Token */}
          <div className="flex flex-row items-start gap-2">
            <img
              src={data.to_token.logoURI}
              alt={data.to_token.symbol}
              className="w-6 h-6 rounded-full"
            />
            <div className="flex flex-col justify-center gap-1">
              <span className="text-white font-medium text-sm leading-6">
                {parseAmount(data.to_amount, data.to_token.decimals)}{" "}
                {data?.to_token.symbol}
              </span>
              <span className="text-gray-400 text-sm leading-6">
                ${Number(data?.to_token.usd_amount)}
              </span>
            </div>
          </div>
        </div>

        {/* Details */}
        <div className="flex flex-col items-center w-full h-103px gap-2">
          {Number(data?.total_fee_amount_usd) > 0 && (
            <div className="flex flex-row items-center px-1 w-full">
              <span className="text-white font-semibold text-sm leading-6">
                Fees
              </span>
              <span className="text-white text-sm leading-6 ml-auto">
                US${Number(data?.total_fee_amount_usd).toFixed(2)}
              </span>
            </div>
          )}
          <div className="flex flex-row items-center px-1 w-full">
            <InfoIcon />
            <span className="text-white font-semibold text-sm leading-6 ml-2">
              Total (USD)
            </span>
            <span className="text-white text-sm leading-6 ml-auto">
              $
              {Number(data?.from_token.usd_amount) +
                Number(data?.total_fee_amount_usd ?? "0")}
            </span>
          </div>
        </div>

        <div className="flex flex-row items-center px-1 w-full">
          <SlippageIcon />
          <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
            Slippage Setting
          </span>
          <span className="text-gray-400 text-sm leading-6 ml-auto">
            {Number(data?.slippage / 100).toFixed(2)}%
          </span>
        </div>

        <div className="flex flex-row items-center px-1 w-full">
          <PercentIcon />
          <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
            Price Impact
          </span>
          <span className="text-gray-400 text-sm leading-6 ml-auto">
            {Number(data?.price_impact) < 0.1
              ? "<0.1%"
              : `${Number(data?.price_impact).toFixed(2)}%`}
          </span>
        </div>

        {data.used_providers && (
          <div className="flex flex-row justify-start items-center px-1 w-full">
            <SwapRoute />
            <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
              Via
            </span>
            <span className="text-gray-400 text-sm leading-6 ml-2">
              {data.used_providers}
            </span>
          </div>
        )}
        <div className="flex justify-start w-full">
          <button
            onClick={onSwap}
            className={`w-full text-white text-sm bg-blue-500 hover:bg-blue-500/80 rounded-lg px-4 py-2 disabled:bg-blue-500/70 disabled:text-gray-300 ${
              loading ? "cursor-not-allowed animate-pulse" : ""
            }`}
            disabled={loading || !!signature}
          >
            {!loading ? "Swap" : "Waiting for signature..."}
          </button>
        </div>
        <div className="flex flex-row items-center px-1 w-full">
          <span className="text-gray-400 font-semibold text-xs leading-6 ml-auto">
            AI Agent:
          </span>
          <span className="text-gray-400 text-xs leading-6 ml-2">
            {data.agent}
          </span>
        </div>
      </div>
    </>
  );
};
