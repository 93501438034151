import { ExternalLink } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";

enum TransactionStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SENT = "SENT", // BITCOIN TRANSACTION TAKES SOME TIME TO APPEAR ON THE SCAN
}

const protocolsExplorers: Record<
  string,
  { ExplorerUrl: string; Text: string }
> = {
  lifi: {
    ExplorerUrl: "https://explorer.li.fi/tx/",
    Text: "Check Bridge/Swap Status on LiFi Explorer",
  },
  squid: {
    ExplorerUrl: "https://axelarscan.io/gmp/",
    Text: "Check Bridge on Axelar Explorer",
  },
  decent: {
    ExplorerUrl: "https://www.decentscan.xyz/",
    Text: "Check Bridge/Swap Status on Decent Explorer",
  },
  synapse: {
    ExplorerUrl: "https://explorer.synapseprotocol.com/txs?hash=",
    Text: "Check Bridge Status on Synapse Explorer",
  },
  meson: {
    ExplorerUrl: "https://explorer.meson.fi/swap/",
    Text: "Check Bridge Status on Meson Explorer",
  },
  rango: {
    ExplorerUrl: "https://scan.rango.exchange/swap/",
    Text: "Check Status on Rango Explorer",
  },
  thorchain: {
    ExplorerUrl: "https://thorchain-explorer-v2.vercel.app/tx/",
    Text: "Check Status on ThorChain Explorer",
  },
};

function buildExplorerUrl(
  protocol: string,
  hash: string,
  chainId?: string | number,
  bridgeId?: string
) {
  if (protocol.toLowerCase() === "decent") {
    return `${
      protocolsExplorers[protocol.toLowerCase()].ExplorerUrl
    }?chainId=${chainId}&txHash=${hash}`;
  }
  if (protocol.toLowerCase() === "rango") {
    // Rango Explorer only works with bridgeId
    return `${
      protocolsExplorers[protocol.toLowerCase()].ExplorerUrl
    }${bridgeId}`;
  }
  return `${protocolsExplorers[protocol.toLowerCase()].ExplorerUrl}${hash}`;
}

export const TxStatus = ({
  data,
}: {
  data: {
    status: TransactionStatus;
    tx_url?: string;
    tx_hash: string;
    chain_id: string | number;
    protocol_name?: string;
    bridge_id?: string;
    wallet_address?: string;
    wallet_type?: string;
    agents_preference?: boolean;
  };
}) => {
  const protocol = data.protocol_name?.toLowerCase();
  const explorer = protocol && protocolsExplorers[protocol];

  const getStatusColor = (status: TransactionStatus) => {
    switch (status) {
      case TransactionStatus.SUCCESS:
        return "bg-green-500";
      case TransactionStatus.PENDING:
      case TransactionStatus.SENT:
        return "bg-yellow-500";
      case TransactionStatus.FAILED:
        return "bg-red-500";
      default:
        return "bg-gray-500";
    }
  };

  const getStatusText = (status: TransactionStatus) => {
    switch (status) {
      case TransactionStatus.SUCCESS:
        return "Confirmed!";
      case TransactionStatus.PENDING:
        return "Pending...";
      case TransactionStatus.SENT:
        return "Sent!";
      case TransactionStatus.FAILED:
        return "Failed!";
      default:
        return "Unknown";
    }
  };

  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          Transaction Status{" "}
          <Badge
            variant="outline"
            className={getStatusColor(data.status)}
            style={{ fontSize: "0.75em", padding: "0.25em 0.75em" }}
          >
            {getStatusText(data.status)}
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        {data.tx_url && (
          <Button variant="outline" asChild>
            <a
              href={data.tx_url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2"
            >
              View Transaction on Blockchain Explorer
              <ExternalLink className="h-4 w-4" />
            </a>
          </Button>
        )}
        {data.status === TransactionStatus.SENT && (
          <CardDescription>
            Transaction may take some time to appear on the explorer and be
            confirmed.
          </CardDescription>
        )}
        {explorer && (
          <Button variant="outline" asChild>
            <a
              href={buildExplorerUrl(
                data.protocol_name!,
                data.tx_hash,
                data?.chain_id,
                data?.bridge_id
              )}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2"
            >
              {protocolsExplorers[data.protocol_name!.toLowerCase()].Text}
              <ExternalLink className="h-4 w-4" />
            </a>
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
