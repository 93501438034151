import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCountryCode = createAsyncThunk(
  "get/countrycode",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      if (response.ok) {
        const data = await response.json();
        return data.country_code;
      } else {
        throw new Error("Failed to fetch country code");
      }
    } catch (error: any) {
      return rejectWithValue("US");
    }
  }
);
