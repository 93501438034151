// aptos-provider.tsx
import { createContext, useContext, useState, useEffect } from "react";
import { WalletSignerContextProvider } from "./wallet-provider";
import { AssembledTransaction } from "../use-wallets";

type PetraWallet = {
  connect: () => Promise<{ address: string }>;
  disconnect: () => Promise<void>;
  account: () => Promise<{ address: string } | null>;
  signAndSubmitTransaction: (
    transaction: AssembledTransaction
  ) => Promise<{ hash: string }>;
  signMessage: (input: { message: string; nonce?: string }) => Promise<{
    signature: string;
    fullMessage: string;
    message: string;
    prefix: string;
    nonce: string;
  }>;
};

declare global {
  interface Window {
    petra?: PetraWallet;
  }
}

export interface SignMessagePayload {
  address?: boolean; // Should we include the address of the account in the message
  application?: boolean; // Should we include the domain of the dapp
  chainId?: boolean; // Should we include the current chain id the wallet is connected to
  message: string; // The message to be signed and displayed to the user
  nonce: string; // A nonce the dapp should generate
}

export interface SignMessageResponse {
  address: string;
  application: string;
  chainId: number;
  fullMessage: string; // The message that was generated to sign
  message: string; // The message passed in by the user
  nonce: string;
  prefix: string; // Should always be APTOS
  signature: string; // The signed full message
}

const AptosWalletContext = createContext<
  WalletSignerContextProvider | undefined
>(undefined);

export const useAptosWallets = () => {
  const context = useContext(AptosWalletContext);
  if (!context) {
    throw new Error(
      "useAptosWallets must be used within an AptosWalletContext"
    );
  }
  return context;
};

export const AptosWalletProvider = ({ children }: { children: any }) => {
  const [address, setAddress] = useState<string | null>(null);

  useEffect(() => {
    // Check if the wallet is already connected on component mount
    checkConnection();
  }, []);

  async function checkConnection() {
    if (window.petra) {
      try {
        const account = await window.petra.account();
        if (account) {
          setAddress(account.address);
        }
      } catch (error) {
        console.error("Failed to check connection", error);
      }
    }
  }

  async function connectWallet() {
    if (!window.petra) {
      throw new Error("Petra wallet is not installed");
    }
    try {
      const { address } = await window.petra.connect();
      setAddress(address);
    } catch (error) {
      console.error("Failed to connect to Petra wallet", error);
      throw error;
    }
  }

  async function disconnectWallet() {
    if (!window.petra) {
      throw new Error("Petra wallet is not installed");
    }
    try {
      await window.petra.disconnect();
      setAddress(null);
    } catch (error) {
      console.error("Failed to disconnect from Petra wallet", error);
      throw error;
    }
  }

  async function signAndSendTransaction(
    tx: AssembledTransaction,
    isLastTransaction: boolean
  ) {
    if (!window.petra || !address) {
      throw new Error("Wallet not connected");
    }
    try {
      const result = await window.petra.signAndSubmitTransaction(tx);
      return result.hash;
    } catch (error) {
      console.error("Failed to sign and send transaction", error);
      throw error;
    }
  }

  async function signMessage(message: string) {
    if (!window.petra || !address) {
      throw new Error("Wallet not connected");
    }
    try {
      const nonce = Date.now().toString();
      const result = await window.petra.signMessage({
        message,
        nonce,
      });
      return result.signature;
    } catch (error) {
      console.error("Failed to sign message", error);
      throw error;
    }
  }

  return (
    <AptosWalletContext.Provider
      value={{
        authSig: null,
        address,
        connect: connectWallet,
        disconnect: disconnectWallet,
        signAndSendTransaction,
        signMessage,
        agentsPreference: false,
        setAgentsPreference: () => {},
      }}
    >
      {children}
    </AptosWalletContext.Provider>
  );
};
