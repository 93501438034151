import { WalletContextProviders } from "./hooks/wallets/context";
import { ChatProvider, useChat } from "./hooks/use-chat";
import { PreferencesProvider } from "./hooks/use-preferences";
import { Outlet } from "react-router-dom";
import SideBar from "./components/sidebar";
import Header from "./components/header";
import { useState } from "react";
import FCMHandler from "./utils/FCMHandler";

function App() {
  const { messages } = useChat();
  const [showBalances, setShowBalances] = useState(false);
  return (
    <PreferencesProvider>
      <WalletContextProviders>
        <ChatProvider>
          <div className="flex h-screen w-screen overflow-hidden">
            {/* Sidebar on the left, taking up only required width */}
            <FCMHandler />
            <SideBar />

            {/* Main content area with flex layout */}
            <div className="flex flex-col flex-1 overflow-hidden">
              {/* Header at the top, fixed in place */}
              <Header
                chatStarted={messages?.length > 0}
                showBalances={showBalances}
                setShowBalances={setShowBalances}
              />

              {/* Outlet for main content, taking up remaining space */}
              <div
                onClick={() => setShowBalances(false)}
                className="flex flex-1"
              >
                <Outlet />
              </div>
            </div>
          </div>
        </ChatProvider>
      </WalletContextProviders>
    </PreferencesProvider>
  );
}

export default App;
