import { Search, TagIcon, UserIcon } from "lucide-react";
import SphereIconLogoGreen from "../assets/images/SphereIconLogoGreen.png";

import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../components/ui/command";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useAppDispatch } from "../redux/hooks";
import { AgentProps } from "./agent-functionality";
import { useChat } from "../hooks/use-chat";
import { useNavigate } from "react-router-dom";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";
import { setCurrentChatId } from "../redux/slices/user";

export default function SearchBarOverlay() {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { availableAgents: agents } = useChat();

  const { restoreOrSetNewChat } = useChat();

  const onToggleModalOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
    setQuery("");
  }, []);

  const handleAgentSelect = useCallback(
    async (agent: AgentProps) => {
    await dispatch(selectPreferredAgentThunk(agent)).unwrap();
    setOpen(false);
    setQuery("");
    dispatch(setCurrentChatId(null));
    restoreOrSetNewChat(null, null);
    navigate("/chat");
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((prevOpen) => !prevOpen);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const filteredAgents = useMemo(
    () =>
      agents.filter(
        (agent: AgentProps) =>
          agent.name.toLowerCase().includes(query.toLowerCase()) ||
          agent.parent?.toLowerCase().includes(query.toLowerCase()) ||
          agent.description?.toLowerCase().includes(query.toLowerCase())
      ),
    [agents, query]
  );

  return (
    <div className="w-full max-w-2xl mx-auto">
      <button
        onClick={onToggleModalOpen}
        className="w-full flex items-center gap-2 h-12 px-4 rounded-lg border bg-background text-muted-foreground hover:bg-accent hover:text-accent-foreground"
      >
        <Search className="h-4 w-4" />
        <span className="flex-1 text-left">Search...</span>
        <kbd className="pointer-events-none hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-xs">⌘</span>K
        </kbd>
      </button>

      <CommandDialog open={open} onOpenChange={onToggleModalOpen}>
        <Command className="rounded-lg border shadow-md min-h-96">
          <div className="flex items-center border-b px-3">
            <CommandInput
              value={query}
              onValueChange={setQuery}
              placeholder="Search agents..."
              className="flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
            />
          </div>
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup heading="Results">
              {filteredAgents.map((agent: AgentProps, index: number) => (
                <CommandItem
                  key={index}
                  className="flex flex-col items-start gap-2 px-4 py-3"
                  onSelect={() => handleAgentSelect(agent)}
                >
                  <AgentCommandCard agent={agent} />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </CommandDialog>
    </div>
  );
}

export const AgentCommandCard = ({ agent }: { agent: AgentProps }) => {
  return (
    <div className="flex w-full items-start gap-3">
      <Avatar className="h-10 w-10">
        <AvatarImage src={agent.img || SphereIconLogoGreen} alt={agent.name} />
        <AvatarFallback>
          {agent.name
            .split(" ")
            .map((word) => word[0])
            .join("")
            .slice(0, 2)}
        </AvatarFallback>
      </Avatar>
      <div className="flex-1 space-y-1">
        <h4 className="text-sm font-semibold">{agent.name}</h4>
        <p className="line-clamp-2 text-sm text-muted-foreground">
          {agent.description}
        </p>
        <div className="flex flex-row gap-x-6">
          <p className="text-sm text-gray-400 flex items-center">
            <TagIcon className="h-2 w-2 mr-2" />
            <span className="font-small text-muted-foreground">
              {agent.action}
            </span>
          </p>
          {agent.parent && (
            <p className="text-sm text-gray-400 flex items-center">
              <UserIcon className="h-2 w-2 mr-2" />
              <span className="font-small text-muted-foreground">
                {agent.parent}
              </span>
            </p>
          )}
        </div>
        <div className="flex items-center gap-2 text-xs text-muted-foreground">
          <span>•</span>
          <span>By {agent.author ?? "SphereOne"}</span>
          <span>•</span>
        </div>
      </div>
    </div>
  );
};
