import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgentProps, AgentSupportedActions } from "../../agent-functionality";
import { useChat } from "../../../hooks/use-chat";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Button } from "../../ui/button";
import {
  ArrowRightEndOnRectangleIcon,
  ArrowsRightLeftIcon,
  ArrowTrendingUpIcon,
  ChartPieIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import {
  CalendarClockIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  HandCoins,
  Landmark,
  ScaleIcon,
  Waypoints,
} from "lucide-react";
import { selectPreferredAgentThunk } from "../../../redux/slices/gptThunk";
import { setCurrentChatId } from "../../../redux/slices/user";

interface QuickActionItem {
  type: "suggested" | "fallback";
  action?: AgentSupportedActions;
  example: string;
  agent?: AgentProps;
  relevanceScore?: number;
}

const INITIAL_DISPLAY_COUNT = 4;

const actionIcons: Record<AgentSupportedActions, React.ReactNode> = {
  [AgentSupportedActions.SWAP]: <ArrowsRightLeftIcon className="h-6 w-6" />,
  [AgentSupportedActions.BRIDGE]: <Waypoints className="h-6 w-6" />,
  [AgentSupportedActions.DEX]: <ChartPieIcon className="h-6 w-6" />,
  [AgentSupportedActions.PERPS]: <ArrowTrendingUpIcon className="h-6 w-6" />,
  [AgentSupportedActions.BALANCE]: <ScaleIcon className="h-6 w-6" />,
  [AgentSupportedActions.ONRAMP]: <Landmark className="h-6 w-6" />,
  [AgentSupportedActions.TRANSFER]: (
    <ArrowRightEndOnRectangleIcon className="h-6 w-6" />
  ),
  [AgentSupportedActions.NFT]: <PhotoIcon className="h-6 w-6" />,
  [AgentSupportedActions.STAKING]: <HandCoins className="h-6 w-6" />,
  [AgentSupportedActions.SCHEDULE]: <CalendarClockIcon className="h-6 w-6" />,
};

const shuffleArray = <T,>(array: T[]): T[] => {
  return [...array].sort(() => Math.random() - 0.5);
};

const QuickActionCard = React.memo(
  ({
    quickAction,
    onClick,
    icon,
    isSuggested = false,
    delay = 0,
  }: {
    quickAction: string;
    onClick: () => void;
    icon?: React.ReactNode;
    isSuggested?: boolean;
    delay?: number;
  }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setIsVisible(true), delay);
      return () => clearTimeout(timer);
    }, [delay]);

    return (
      <Button
        variant="outline"
        className={`rounded-full transition-opacity duration-700 ${
          isVisible ? "opacity-100" : "opacity-0"
        } max-w-[350px] sm:max-w-full`}
        onClick={onClick}
      >
        {icon}
        <p className="font-medium text-xs sm:text-sm flex justify-center align-middle items-center overflow-hidden">
          {quickAction}
        </p>
      </Button>
    );
  }
);

QuickActionCard.displayName = "QuickActionCard";

const QuickActionCards = () => {
  const {
    availableAgents: agents,
    appendMessage,
    superAgent,
    restoreOrSetNewChat,
    setMessages,
  } = useChat();
  const { suggestedActions } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [displayCount, setDisplayCount] = useState(INITIAL_DISPLAY_COUNT);

  const displayedActions = useMemo(() => {
    const suggestedItems: QuickActionItem[] = (suggestedActions || []).map(
      (suggestion) => ({
        type: "suggested",
        action: suggestion.action as AgentSupportedActions,
        example: suggestion.example,
        relevanceScore: suggestion.relevanceScore,
      })
    );

    const remainingCount = displayCount - suggestedItems.length;
    const fallbackItems: QuickActionItem[] =
      remainingCount > 0
        ? shuffleArray(agents as AgentProps[])
            .slice(0, remainingCount)
            .flatMap((agent) =>
              agent.examples.map((example: string) => ({
                type: "fallback",
                action: agent.action,
                example,
                agent,
              }))
            )
        : [];

    return [...suggestedItems, ...fallbackItems].slice(0, displayCount);
  }, [suggestedActions, agents, displayCount]);

  const handleActionClick = useCallback(
    async (item: QuickActionItem) => {
      setMessages([]);
      restoreOrSetNewChat(null, null);
      dispatch(setCurrentChatId(null));
      appendMessage(item.example);
      await dispatch(
        selectPreferredAgentThunk(
          item.type === "suggested" ? superAgent : item.agent!
        )
      );
    },
    [appendMessage, dispatch, superAgent]
  );

  const toggleDisplayCount = useCallback(() => {
    setDisplayCount((prev) =>
      prev === INITIAL_DISPLAY_COUNT ? 8 : INITIAL_DISPLAY_COUNT
    );
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full gap-2 mb-4 sm:mb-0">
      <div className="flex flex-wrap justify-center gap-1 sm:gap-2 w-full">
        {displayedActions.map((item, index) => (
          <QuickActionCard
            key={`${index}-${item.example}`}
            quickAction={item.example}
            icon={item.action ? actionIcons[item.action] : undefined}
            isSuggested={item.type === "suggested"}
            delay={index * 100} // Delay each card by 100ms
            onClick={() => handleActionClick(item)}
          />
        ))}
      </div>

      {/* Show More Button */}
      <Button
        variant="outline"
        className={`rounded-full px-4 py-2 font-medium text-sm transition-all duration-200 ease-in-out
    ${
      displayCount === INITIAL_DISPLAY_COUNT
        ? "bg-primary text-secondary hover:bg-primary/80"
        : "bg-gray-200 text-primary/70 hover:bg-gray-300"
    } shadow-lg hover:shadow-xl transform hover:scale-105`}
        onClick={toggleDisplayCount}
      >
        <span className="flex items-center">
          {displayCount === INITIAL_DISPLAY_COUNT ? (
            <>
              Show More
              <ChevronDownIcon className="h-4 w-4 ml-1" />
            </>
          ) : (
            <>
              Show Less
              <ChevronUpIcon className="h-4 w-4 ml-1" />
            </>
          )}
        </span>
      </Button>
    </div>
  );
};

export default QuickActionCards;
