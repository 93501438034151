import { useEffect } from "react";
import { useChat } from "../hooks/use-chat";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "../hooks/use-toast";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";
import { useAppDispatch } from "../redux/hooks";
import { useWallets } from "../hooks/wallets/use-wallets";

function FCMHandler() {
  const { appendMessage, superAgent } = useChat();
  const { addresses } = useWallets();
  const dispatch = useAppDispatch();

  const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        );
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      } catch (error) {
        console.error("Service Worker registration failed:", error);
      }
    }
  };


  const fetchFCMToken = async (messaging: any) => {
    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID,
      });
      if (!token) {
        console.error(
          "User didn't want to receive notifications or didn't connect wallet yet to be asked for notifications"
        );
      }
    } catch (error) {
      console.error("Error fetching FCM token:", error);
    }
  };

  const showBrowserNotification = (title: string, options: any) => {
    if (Notification.permission === "granted") {
      try {
        new Notification(title, options);
      } catch (error) {
        console.error("Error showing notification:", error);
      }
    } else {
      console.log("Notification permission not granted");
    }
  };

  useEffect(() => {
    const messaging = getMessaging();
    registerServiceWorker();
    fetchFCMToken(messaging);

    onMessage(messaging, async (payload) => {
      const notificationTitle =
        payload?.notification?.title || "SphereOne Yield Reminder";
      const notificationOptions = {
        body: payload?.notification?.body || "Default body content",
      };

      toast({
        title: notificationTitle,
        description: notificationOptions.body,
        onClick: async () => {
          try {
            await dispatch(selectPreferredAgentThunk(superAgent)).unwrap();
            await appendMessage("I want to know the Top Pools on AAVE V3");
          } catch (error) {
            console.error("Error dispatching preferred agent:", error);
          }
        },
      });

      showBrowserNotification(notificationTitle, notificationOptions);
    });
  }, [addresses]);

  return null;
}

export default FCMHandler;
