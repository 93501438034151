import { createSlice } from "@reduxjs/toolkit";
import { AgentProps } from "../../components/agent-functionality";
import { selectPreferredAgentThunk } from "./gptThunk";

export interface GPTState {
  conversationStarters: Array<string>;
  preferredAgent: AgentProps | null;
}

const initialState: GPTState = {
  conversationStarters: ["Integrate Polygon", "Integrate Avalanche"],
  preferredAgent: null,
};

const gptSlice = createSlice({
  name: "gpt",
  initialState,
  reducers: {
    addConversationStarter: (state, action) => {
      state.conversationStarters.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectPreferredAgentThunk.pending, (state) => {
        state.preferredAgent = null;
        state.conversationStarters = [];
      })
      .addCase(selectPreferredAgentThunk.fulfilled, (state, action) => {
        state.preferredAgent = action.payload;
        state.conversationStarters =
          action.payload?.examples ?? state.conversationStarters;
      })
      .addCase(selectPreferredAgentThunk.rejected, (state, action) => {
        state.preferredAgent = null;
        state.conversationStarters = [];
      });
  },
});

export const { addConversationStarter } = gptSlice.actions;
export default gptSlice.reducer;
