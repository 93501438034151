import ConnectWalletButton from "./connect-wallet-button";
import { AgentBuilderDemoButton } from "./agent-builder-demo";

import { ThemedButton } from "../components/themed/ThemedButton";
import { useSidebar } from "../components/ui/sidebar";
import { PanelLeftOpen, PanelRightOpen, Wallet } from "lucide-react";
import WalletPopUp from "./balance-popup";
import { useWallets } from "../hooks/wallets/use-wallets";
import { useAppSelector } from "../redux/hooks";

export default function Header({
  chatStarted,
  showBalances,
  setShowBalances,
}: {
  chatStarted: boolean;
  showBalances: boolean;
  setShowBalances: (show: boolean) => void;
}) {
  const { addresses } = useWallets();
  const { toggleSidebar, state } = useSidebar();
  const { preferredAgent } = useAppSelector((state) => state.gpt);

  return (
    <div className="px-4 py-1 border-b border-b-gray-200 dark:border-b-gray-700 flex justify-between">
      <div className="flex space-x-4 items-center">
        <ThemedButton className="p-3" onClick={toggleSidebar}>
          {state === "expanded" ? (
            <PanelRightOpen className="h-6 w-6" />
          ) : (
            <PanelLeftOpen className="h-6 w-6" />
          )}
        </ThemedButton>
        <h1 className="text-lg font-semibold">
          {preferredAgent ? preferredAgent.name : "Surface Area"}
        </h1>
      </div>

      <div className="flex space-x-4 items-center">
        <div className="hidden sm:flex gap-3 items-center">
          <AgentBuilderDemoButton />
        </div>
        <div className="flex space-x-2 items-center">
          <ConnectWalletButton chatStarted={chatStarted} />
        </div>

        {Object.values(addresses).some((address) => address) && (
          <button onClick={() => setShowBalances(!showBalances)}>
            <Wallet className="h-6 w-6" />
          </button>
        )}
        {showBalances && (
          <div className="fixed top-10 right-4 z-30 transition-all ease-in-out duration-300">
            <WalletPopUp setShowBalances={setShowBalances} />
          </div>
        )}
      </div>
    </div>
  );
}
