import { createAsyncThunk } from "@reduxjs/toolkit";
import { AgentProps } from "../../components/agent-functionality";

export const selectPreferredAgentThunk = createAsyncThunk(
  "gpt/selectPreferredAgent",
  async (agent: AgentProps | null, { rejectWithValue }) => {
    try {
      return agent;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
