import React, { useState, useMemo } from "react";

import { Button } from "../ui/button"
import { ThemedButton } from "../themed/ThemedButton";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Label } from "../ui/label";
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group"
import { ScrollArea } from "../ui/scroll-area";
import { Input } from "../ui/input";

import { AgentSupportedActions } from "../agent-functionality";
import { images } from "../../assets/images";

export interface SupportedChain { image: string; name: string };

interface FilterDialogButtonProps {
  allChains: SupportedChain[];
  onApplyFilter: ({
    selectedAction,
    selectedChains
  }: {
    selectedAction: string;
    selectedChains: string[];
  }) => void;
  filtersApplied: number;
}


const FilterDialogButton = ({
  allChains,
  onApplyFilter,
  filtersApplied
}: FilterDialogButtonProps) => {
  // categories
  const categoryList = useMemo(() => ["ALL", ...Object.values(AgentSupportedActions)], [Object.values(AgentSupportedActions)])
  // store all chains, so they remain in memory
  const memoizedChains = useMemo(() => allChains, [allChains]);
  
  const [selectedAction, setSelectedAction] = useState<string>("ALL");
  const [searchChain, setSearchChain] = useState<string>("");
  const [selectedChains, setSelectedChains] = useState<SupportedChain[]>([]);
  
  function sortChainsWithSelectedFirst(
    chains: SupportedChain[],
    selectedChains: SupportedChain[] // The selected chains are still identified by their names
  ): SupportedChain[] {
    return chains.sort((a, b) => {
      const isASelected = selectedChains.some(chain => chain.name === a.name);
      const isBSelected = selectedChains.some(chain => chain.name === b.name);
  
      if (isASelected && !isBSelected) {
        return -1; // 'a' comes before 'b' if 'a' is selected and 'b' is not
      } else if (!isASelected && isBSelected) {
        return 1; // 'b' comes before 'a' if 'b' is selected and 'a' is not
      } else {
        // If both are selected or neither is selected, sort alphabetically by name
        return a.name.localeCompare(b.name);
      }
    });
  }
  const filteredChains = useMemo(() => {
    let filtered_chains = memoizedChains;
    if (searchChain.trim().length > 0) {
      // if searchChain is NOT empty
      filtered_chains = memoizedChains.filter((memChain: SupportedChain) => memChain.name.toLowerCase().includes(searchChain));
    }
    if (selectedChains.length > 0) {
      const updated_list = sortChainsWithSelectedFirst(filtered_chains, selectedChains);
      filtered_chains = updated_list;
    }
    return filtered_chains
  }, [memoizedChains, searchChain, selectedChains])

  const resetFilters = () => {
    setSearchChain("");
    setSelectedAction("ALL")
    setSelectedChains([])
  }

  return (
    <React.Fragment>
      <Dialog>
        <DialogTrigger asChild>
          <div className="flex flex-col relative">
            {filtersApplied > 0 && (<div className="absolute -right-2 -top-2 h-4 w-4 bg-red-500 p-3 rounded-full flex justify-center items-center">
              <p className="text-sm">{filtersApplied}</p>
            </div>)}
            <ThemedButton>Filters</ThemedButton>
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader className="items-center space-y-3">
            <DialogTitle className='flex justify-center'>Filters</DialogTitle>
            <DialogDescription>
              Filter agents by Categories and Supported Chains.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col">
            <div className="flex flex-col space-y-2 mb-4">
              <Label className="text-md font-semibold">Categories ({categoryList.length})</Label>
              <div className="flex">
                <ToggleGroup
                  type="single"
                  className="flex flex-wrap gap-1 justify-start"
                  value={selectedAction}
                  onValueChange={(v) => {
                    // NOTE: For `single` toggle-group, onValueChange will return a value when "selecting".
                    // But when "de-selecting", it will return an empty string.
                    // By default, `ALL` is selected.
                    if (v === "" && selectedAction === "ALL") return;
                    else if (v === "") setSelectedAction("ALL"); // always default to `ALL` if user de-selects
                    else setSelectedAction(v)
                  }}
                >
                  {categoryList.map((action: string, index: number) => (
                    <ToggleGroupItem
                      key={action}
                      value={action}
                      className="text-sm rounded-lg border data-[state=on]:text-white data-[state=on]:bg-blue-500 hover:bg-primary/90 hover:text-white"
                    >
                      {action}
                    </ToggleGroupItem>
                  ))}
                </ToggleGroup>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Label className="text-md font-semibold">Supported Chains ({memoizedChains.length})</Label>
              <Input
                placeholder={"Search chains..."}
                value={searchChain}
                onChange={(e) => setSearchChain(e.target.value)}
              />
              <div className="relative">
                <div className="absolute inset-x-0 top-0 h-3 bg-gradient-to-b from-background to-transparent z-10"></div>
                <div className="absolute inset-x-0 bottom-0 h-3 bg-gradient-to-t from-background to-transparent z-10"></div>
                <ScrollArea className="h-56 rounded">
                  <ToggleGroup
                    type="multiple"
                    className="flex flex-wrap gap-1 justify-start"
                    value={selectedChains.map(chain => chain.name)}
                    onValueChange={(value) => setSelectedChains(memoizedChains.filter(chain => value.includes(chain.name)))}
                  >
                    {filteredChains.map((chain: SupportedChain) => (
                      <ToggleGroupItem
                        key={chain.name}
                        value={chain.name}
                        className="rounded-lg border data-[state=on]:text-white data-[state=on]:bg-blue-500 hover:bg-primary/90 hover:text-white"
                      >
                        <div className="flex flex-row items-center space-x-2">
                          <img
                            src={chain.image ? chain.image : images.DefaultCryptoLogo}
                            alt={chain.name}
                            className="size-4 rounded"
                            loading="lazy"
                          />
                          <p>{chain.name}</p>
                        </div>
                      </ToggleGroupItem>
                    ))}
                  </ToggleGroup>
                </ScrollArea>
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <ThemedButton onClick={() => {
                // apply in parent
                onApplyFilter({
                  selectedAction,
                  selectedChains: selectedChains.map((selChain: SupportedChain) => selChain.name)
                })
                // reset in child
                resetFilters()
            }}>
                Apply Filters
              </ThemedButton>
            </DialogClose>
            <Button
              variant="outline"
              onClick={() => resetFilters()}
            >
              Reset Filters
            </Button>
            <DialogClose asChild>
              {/* Close without doing anything */}
              <Button variant="outline">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export { FilterDialogButton };
