import React from "react";
import { Button, ButtonProps } from "../ui/button";
import { cn } from "../../lib/utils";

interface ThemedButtonProps extends ButtonProps {
  className?: string;
}

/**
 * ThemedButton has ALL the features of a Shadcn-UI `Button`.
 * But for Shadcn-UI `Button`, their button text is, by default, `black`, in dark mode. And `white` in light mode.
 * So, this button simply overrides the button text color to `white` regardless of light/dark mode.
 * 
 * SUPER BIG NOTE:
 * ONLY EVER USE THIS BUTTON AS PRIMARY BUTTONS. NO VARIANTS LIKE `OUTLINE` OR `SECONDARY` OR `DESTRUCTIVE` OR OTHERS.
 * IF YOU WANT TO USE BUTTONS OF VARIANT `GHOST`, `LINK`, `OUTLINE`, `SECONDARY`, `DESTRUCTIVE`,
 * PLEASE USE THE STANDARD SHADCN-UI `BUTTON`.
 */
const ThemedButton = React.forwardRef<HTMLButtonElement, ThemedButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <Button ref={ref} className={cn("text-white", className)} {...props} />
    );
  }
);

ThemedButton.displayName = "ThemedButton";

export { ThemedButton };
