import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Loader2 } from "lucide-react";
import { useAppDispatch } from "../../../redux/hooks";
import { setNfts } from "../../../redux/slices/user";

export interface NFT {
  id?: string;
  chain?: string;
  name: string;
  symbol?: string;
  description: string;
  image: string;
  contentType: string;
  attributes?: {
    trait_type: string;
    value: string;
  }[];
  collection?: {
    name?: string;
    family?: string;
  };
  properties?: {
    creators?: {
      address?: string;
      share?: number;
    }[];
    category?: string;
    files?: {
      uri?: string;
      type?: string;
    }[];
  };
  mint?: string;
}

interface LoadingStates {
  [key: string]: boolean;
}

interface NFTGridViewProps {
  data: {
    nfts: NFT[];
  };
}

interface NFTCardProps {
  nft: NFT;
  index: number;
  delay: number;
}

export const isVideo = (contentType: string | undefined): boolean => {
  if (!contentType) return false;
  return (
    contentType.startsWith("video/") ||
    [".mp4", ".webm", ".mov", ".avi", "video"].some((ext) =>
      contentType.toLowerCase().includes(ext)
    )
  );
};

export const NFTCard: React.FC<NFTCardProps> = React.memo(
  ({ nft, index, delay = 0 }) => {
    const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
    const [errorStates, setErrorStates] = useState<LoadingStates>({});
    const [isVisible, setIsVisible] = useState(false);

    const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
    const cardId = nft.id || index;
    const mediaUrl = nft.image;

    const handleMediaLoad = useCallback((id: string | number): void => {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
      setErrorStates((prev) => ({ ...prev, [id]: false }));
    }, []);

    const handleMediaError = useCallback((id: string | number): void => {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
      setErrorStates((prev) => ({ ...prev, [id]: true }));
    }, []);

    const handleImageClick = () => {
      setEnlargedImage(mediaUrl);
    };

    const closeModal = () => {
      setEnlargedImage(null);
    };

    useEffect(() => {
      const timer = setTimeout(() => setIsVisible(true), delay);
      return () => clearTimeout(timer);
    }, [delay]);

    return (
      <div
        className={`transition-opacity duration-700 ${isVisible ? "opacity-100" : "opacity-0"} `}
      >
        <div className="relative group rounded-lg overflow-hidden shadow-md transition-all duration-300 cursor-pointer">
          <div className="aspect-square relative">
            {loadingStates[cardId] && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
              </div>
            )}

            {errorStates[cardId] ? (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100 text-gray-500">
                <span>Failed to load NFT</span>
              </div>
            ) : isVideo(nft.contentType) ? (
              <video
                src={mediaUrl}
                controls={true}
                autoPlay={false}
                className="relative w-full h-full object-cover z-20"
                preload="metadata"
                onLoadedData={() => {
                  handleMediaLoad(cardId);
                }}
                onError={() => {
                  handleMediaError(cardId);
                }}
              >
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={mediaUrl}
                alt={nft.name || `NFT ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300 cursor-pointer relative z-20"
                onClick={handleImageClick}
                onLoad={() => handleMediaLoad(cardId)}
                onError={() => handleMediaError(cardId)}
              />
            )}
          </div>

          {enlargedImage && (
            <div
              className="fixed inset-0 z-50 py-20 flex items-center justify-center bg-black bg-opacity-75"
              onClick={closeModal}
            >
              <img
                src={enlargedImage}
                alt="Enlarged NFT"
                className="max-w-full max-h-full"
              />
            </div>
          )}

          <div className="absolute inset-0 bg-black bg-opacity-0 transition-all duration-300 flex items-end">
            <div className="p-4 text-white transform translate-y-full transition-transform duration-300">
              <h3 className="font-bold truncate">
                {nft.name || `NFT ${index + 1}`}
              </h3>
              {nft.collection?.name && (
                <p className="text-sm opacity-75 truncate">
                  {nft.collection.name}
                </p>
              )}
              {nft.description && (
                <p className="text-xs opacity-75 truncate mt-1">
                  {nft.description}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const NFTGridView: React.FC<NFTGridViewProps> = ({ data }) => {
  const dispatch = useAppDispatch();

  const currentNfts = useMemo(() => {
    if (!data?.nfts) return [];
    const filteredNfts = data.nfts
      .flat()
      .filter(
        (nft): nft is NFT =>
          nft !== null &&
          typeof nft.image === "string" &&
          !nft.image.endsWith(".link/") &&
          nft.contentType !== "unknown"
      );
    dispatch(setNfts(filteredNfts));
    return filteredNfts;
  }, [data?.nfts]);

  if (currentNfts.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 text-gray-500">
        No NFTs found
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[repeat(auto-fill,minmax(245px,1fr))] gap-4 p-4">
      {currentNfts.map((nft, index) => (
        <NFTCard
          key={nft.id || index}
          nft={nft}
          index={index}
          delay={index * 100}
        />
      ))}
    </div>
  );
};

export default NFTGridView;
