import axios, { AxiosInstance } from "axios";
import { Balance } from "../components/chat/balances";

export interface GptModel {
  label: string;
  model: string;
}

export interface BalanceResponse {
  balances: Balance[];
  suggestedActions: SuggestedAction[];
  errors: Record<string, any>;
}

export interface SuggestedAction {
  action: string;
  example: string;
  relevanceScore: number;
  applicableChains: string[];
  estimatedValue: number;
}

class ServerApi {
  private api: AxiosInstance;

  constructor(baseUrl: string) {
    this.api = axios.create({
      baseURL: baseUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  submitChatTransaction(
    chatId: string,
    signature: string,
    wallet_address: string
  ) {
    return this.api.post(`${chatId}/submit`, {
      signature,
      wallet_address,
    });
  }

  async getBalances(
    evm_wallet: string | null,
    solana_wallet: string | null,
    sui_wallet: string | null,
    cosmos_wallet: string | null,
    bitcoin_wallet: string | null
  ): Promise<BalanceResponse> {
    try {
      if (
        !evm_wallet &&
        !solana_wallet &&
        !sui_wallet &&
        !cosmos_wallet &&
        !bitcoin_wallet
      ) {
        return { balances: [], suggestedActions: [], errors: {} };
      }

      const response = await this.api.get(
        `balance?evm_wallet=${evm_wallet}&solana_wallet=${solana_wallet}&sui_wallet=${sui_wallet}&cosmos_wallet=${cosmos_wallet}&bitcoin_wallet=${bitcoin_wallet}`
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching balances: ", error);
      throw error;
    }
  }

  async sendTipLink(tipLinkUrl: string, toEmail: string) {
    return this.api.post("send-tiplink", {
      tiplink_url: tipLinkUrl,
      to_email: toEmail,
    });
  }

  async getSupportedGptModels(): Promise<GptModel[]> {
    return (await this.api.get("models")).data.models;
  }

  async getAvailableAgents(): Promise<any[]> {
    return (await this.api.get("agents?split=true")).data.agents;
  }

  async getChainExplorerUrl(chainName: string): Promise<string> {
    return (await this.api.get(`explorer/${chainName}`)).data.explorer_url;
  }

  async getChains(): Promise<any[]> {
    try {
      const response = await this.api.get("chains");
      return response.data.chains;
    } catch (error) {
      console.error("Error fetching chains:", error);
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(
          error.response.data.error || "An error occurred while fetching chains"
        );
      }
      throw new Error("An error occurred while fetching chains");
    }
  }

  async check_and_send_gas_from_gas_station(
    chainId: string,
    userAddress: string
  ) {
    return this.api.post("faucet", {
      chain_id: chainId,
      user_address: userAddress,
    });
  }

  async save_fcm_user_token(
    walletAddress: string,
    fcmToken: string,
    timezone: string
  ) {
    return this.api.post("user-fcm-token", {
      fcm_token: fcmToken,
      timezone: timezone,
      wallet_address: walletAddress,
    });
  }

  async getChatsHistory(walletAddress: string) {
    return this.api.get(`get-chats-history?wallet_address=${walletAddress}`);
  }

  async deleteChatFromHistory(chatId: string, walletAddress: string) {
    try {
      const response = await this.api.post("delete-chat", {
        chat_id: chatId,
        wallet_address: walletAddress,
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting chat from history:", error);
      throw new Error("An error occurred while deleting chat from history");
    }
  }
}

export const serverApi = new ServerApi(
  process.env.REACT_APP_CREATOR_ENDPOINT as string
);
