import { ShieldExclamationIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { ThemedButton } from "../themed/ThemedButton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog"

interface LockPeriodProps {
  chains: string[];
}

// This is an object to store the lock time period for chains
const lockChainPeriods: { [name: string]: number } = {
  DEGEN: 4,
  EDGELESS: 7,
  HAM: 7,
  XAI: 7,
};

const LockPeriod = ({ chains }: LockPeriodProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [chain, setChain] = useState<string>("");

  useEffect(() => {
    const lockChains = Object.keys(lockChainPeriods);
    const originChainLock = lockChains.includes(chains[0]);
    const destChainLock = lockChains.includes(chains[1]);

    if (originChainLock || destChainLock) setVisible(true);

    if (originChainLock) setChain(chains[0]);
    if (destChainLock) setChain(chains[1]);
  }, [chains]);

  return (
    <Dialog open={visible} onOpenChange={(open: boolean) => setVisible(open)}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader className="gap-4">
          <div className="mx-auto rounded-full p-3 bg-secondary">
            <ShieldExclamationIcon className="w-6 h-6" />
          </div>
          <DialogTitle className="text-center text-xl font-semibold">Warning!</DialogTitle>
          <DialogDescription className="text-center space-y-2">
            <p>{`Your transaction involves ${chain} chain.`}</p>
            <p>{`This chain has a lock time period of ${lockChainPeriods[chain]} days.`}</p>
            <p>You won't be able to withdraw until that time has passed.</p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-center">
          <ThemedButton onClick={() => setVisible(false)} className="w-full bg-blue-500 sm:w-auto">
            I Understand
          </ThemedButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LockPeriod;
