import { createContext, useContext } from "react";
import { WalletSignerContextProvider } from "./wallet-provider";

import {
  useConnectWallet,
  useCurrentAccount,
  useDisconnectWallet,
  useSignPersonalMessage,
  useSignTransaction,
  useSuiClient,
  useWallets,
} from "@mysten/dapp-kit";

import { Transaction } from "@mysten/sui/transactions";
import { serverApi } from "../../../services/server";
import { useAppDispatch } from "../../../redux/hooks";
import {
  setIsLoading,
  getBalances,
  updateBalancesAfterTransaction,
  setChatHistory,
  removeChatHistory,
} from "../../../redux/slices/user";

const SuiWalletContext = createContext<WalletSignerContextProvider | undefined>(
  undefined
);

export const useSuiWallets = () => {
  const context = useContext(SuiWalletContext);
  if (!context) {
    throw new Error("useSuiWallets must be used within a SuiWalletContext");
  }
  return context;
};

export const SuiWalletProvider = ({ children }: { children: any }) => {
  const suiWallet = useWallets();
  const currentAccount = useCurrentAccount();
  const client = useSuiClient();
  const { mutateAsync: connect } = useConnectWallet();
  const { mutate: disconnect } = useDisconnectWallet();
  const { mutateAsync: signTransaction } = useSignTransaction();
  const { mutate: signPersonalMessage } = useSignPersonalMessage();

  const dispatch = useAppDispatch();

  async function connectWallet() {
    try {
      if (suiWallet.length > 0) {
        const connectionResult = await connect({ wallet: suiWallet[0] });
        const account = connectionResult.accounts?.[0];
        if (account && account.address) {
          // Fetch balances without blocking
          serverApi
            .getBalances(null, null, account.address, null, null)
            .then((SuiWalletBalances) => {
              dispatch(getBalances(SuiWalletBalances));
            });

          const get_chat_history_response = await serverApi.getChatsHistory(
            account.address
          );
          const chats = get_chat_history_response.data.chats_history;

          dispatch(setChatHistory(chats));
        } else {
          console.error("No address found in account data.");
        }
      } else {
        throw new Error("Please install Sui wallet");
      }
    } catch (error) {
      console.error("Failed to connect to Sui wallet", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  async function disconnectWallet() {
    try {
      if (currentAccount?.address) {
        dispatch(removeChatHistory(currentAccount.address));
      }
      await disconnect();
    } catch (error) {
      console.error("Failed to disconnect from Sui wallet", error);
      throw error;
    }
  }

  async function signAndSendTransaction(tx: any, isLastTransaction: boolean) {
    try {
      const transactionWrapped = Transaction.from(tx);
      transactionWrapped.setGasBudget(5000000);

      const { bytes, signature, reportTransactionEffects } =
        await signTransaction({
          transaction: transactionWrapped,
          chain: "sui:mainnet",
        });

      const executeResult = await client.executeTransactionBlock({
        transactionBlock: bytes,
        signature,
        options: {
          showRawEffects: true,
        },
      });

      reportTransactionEffects(executeResult.rawEffects! as any);
      const address = currentAccount?.address;

      if (isLastTransaction) {
        if (address) {
          const response = await serverApi.getBalances(
            null,
            null,
            address,
            null,
            null
          );
          dispatch(updateBalancesAfterTransaction(response));
        }
      }

      return executeResult.digest;
    } catch (error) {
      console.error("Failed to sign and send transaction", error);
      throw error;
    }
  }

  function signMessage(message: string) {
    try {
      const signature = signPersonalMessage({
        message: new TextEncoder().encode(message),
      });
      return signature;
    } catch (error) {
      console.error("Failed to sign message", error);
      throw error;
    }
  }

  return (
    <SuiWalletContext.Provider
      value={{
        authSig: null,
        address: currentAccount?.address ?? null,
        connect: connectWallet,
        disconnect: disconnectWallet,
        signAndSendTransaction,
        signMessage: signMessage,
        agentsPreference: false,
        setAgentsPreference: () => {},
      }}
    >
      {children}
    </SuiWalletContext.Provider>
  );
};
