import { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../ui/alert-dialog";
import { Card, CardContent } from "../../../ui/card";
import SphereIconLogoGreen from "../../../../assets/images/SphereIconLogoGreen.png";

interface ReserveData {
  currentATokenBalance: string;
  liquidityRate: string;
  reserve: {
    id: string;
    symbol: string;
    name: string;
    underlyingAsset: string;
    decimals: number;
    currentApy: string;
    logoURI?: string;
    token_price?: string;
  };
}

interface ActivePositionsResponse {
  from_address: string;
  active_positions: {
    [chain: string]: {
      borrowedReservesCount: number;
      id: string;
      lifetimeRewards: string;
      reserves: ReserveData[];
      rewardsLastUpdated: number;
      unclaimedRewards: string;
    } | null;
  };
  agent: string;
}

export function YieldActivePositions({
  data,
  onDone,
}: {
  data: ActivePositionsResponse;
  onDone: () => void;
}) {
  const [error, setError] = useState<string | null>(null);

  const activePositions = Object.entries(data.active_positions)
    .filter(([chain, chainData]) => chainData && chainData.reserves)
    .map(([chain, chainData]) => {
      if (!chainData) return null;
      return {
        chain,
        reserves: chainData.reserves,
      };
    })
    .filter(
      (item): item is { chain: string; reserves: ReserveData[] } =>
        item !== null
    );

  const handleClick = (chain: string) => {
    if (chain.toLowerCase() === "binance") {
      chain = "bnb";
    } else if (chain.toLowerCase() === "ethereum") {
      chain = "mainnet";
    }
    const url = `https://app.aave.com/?marketName=proto_${chain.toLowerCase()}_v3`;
    window.open(url, "_blank", "noopener noreferrer");
  };

  return (
    <Card className="mt-2 max-w-[550px]">
      <CardContent className="flex flex-col">
        <h2 className="mt-4 text-foreground font-medium text-md ml-4 block text-left">
          Active Positions on AAVE V3 Protocol
        </h2>
        {activePositions.map(({ chain, reserves }) => (
          <div
            key={chain}
            className="w-full bg-background rounded-lg py-1 cursor-pointer"
            onClick={() => handleClick(chain)}
          >
            <CardContent className="px-2 py-1">
              {reserves.map((reserve: ReserveData) => (
                <div
                  key={reserve.reserve.id}
                  className="flex justify-between items-center bg-secondary px-4 py-2 rounded-lg mb-2"
                >
                  <div className="flex flex-row items-start gap-2 items-center">
                    <img
                      src={reserve.reserve.logoURI || SphereIconLogoGreen}
                      alt={`Logo of ${reserve.reserve.symbol}`}
                      className="w-8 h-8 rounded-full"
                    />
                    <div className="flex flex-col items-start">
                      <span className="font-medium text-sm">
                        {reserve.reserve.symbol}
                      </span>
                      <span className="text-muted-foreground text-xs">
                        {chain}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-medium text-xs">
                      Current APY:{" "}
                      {parseFloat(reserve.reserve.currentApy).toFixed(2)}%
                    </span>
                    <span className="text-xs">
                      Balance:{" "}
                      {(
                        parseFloat(reserve.currentATokenBalance) /
                        10 ** reserve.reserve.decimals
                      ).toFixed(6)}
                    </span>
                    <span className="text-muted-foreground text-xs">
                      Total Value USD:{" "}
                      {reserve.reserve.token_price && reserve.reserve.decimals
                        ? `$ ${((parseFloat(reserve.currentATokenBalance) / 10 ** reserve.reserve.decimals) * parseFloat(reserve.reserve.token_price)).toFixed(2)}`
                        : "N/A"}
                    </span>
                  </div>
                </div>
              ))}
            </CardContent>
          </div>
        ))}
      </CardContent>
      <AlertDialog open={!!error} onOpenChange={() => setError(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Oops!</AlertDialogTitle>
            <AlertDialogDescription>{error}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setError(null)}>
              Accept
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
}
