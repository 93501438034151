import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { getCountryCode } from "./userThunk";
import { Balance } from "../../components/chat/balances";
import { BalanceResponse, SuggestedAction } from "../../services/server";
import { NFT } from "../../components/chat/message-actions/nfts";
import { uuidv7 } from "uuidv7";

enableMapSet();

export interface UserState {
  countryCode: string;
  balances?: Balance[];
  suggestedActions?: SuggestedAction[];
  destinationToken?: string;
  errors?: {};
  isLoading?: boolean;
  connectedWallets: string[];
  nfts: NFT[] | null;
  chatHistory?: {
    [chatId: string]: {
      messages: {
        message: string;
        agent: string;
        data?: any | null;
        component?: string | null;
      }[];
      lastUpdatedAt: string;
      associated_wallet?: string | null;
    };
  };
  currentChatId?: string | null;
}

const initialState: UserState = {
  countryCode: "US",
  isLoading: false,
  connectedWallets: [],
  nfts: null,
  chatHistory: {},
  currentChatId: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getBalances: (state, action: PayloadAction<BalanceResponse>) => {
      const existingAddresses = new Set(
        state.balances?.map((b) => b.wallet_address?.toLowerCase())
      );

      const newBalances = action.payload.balances.filter(
        (b) => !existingAddresses.has(b.wallet_address?.toLowerCase())
      );

      state.balances = [...(state.balances ?? []), ...newBalances];

      state.suggestedActions = [
        ...(state.suggestedActions ?? []),
        ...action.payload.suggestedActions,
      ].sort((a, b) => (b.relevanceScore ?? 0) - (a.relevanceScore ?? 0));

      state.errors = action.payload.errors;
      state.isLoading = false;
    },
    updateBalancesAfterTransaction: (
      state,
      action: PayloadAction<BalanceResponse>
    ) => {
      const updatedBalances = action.payload.balances.map((newBalance) => {
        const existingBalance = state.balances?.find(
          (b) =>
            b.wallet_address?.toLowerCase() ===
            newBalance.wallet_address?.toLowerCase()
        );
        return existingBalance
          ? { ...existingBalance, ...newBalance }
          : newBalance;
      });

      state.balances = [
        ...updatedBalances,
        ...(state.balances?.filter(
          (b) =>
            !updatedBalances.some(
              (ub) =>
                ub.wallet_address?.toLowerCase() ===
                b.wallet_address?.toLowerCase()
            )
        ) ?? []),
      ];

      state.suggestedActions = [
        ...(state.suggestedActions ?? []),
        ...action.payload.suggestedActions,
      ].sort((a, b) => (b.relevanceScore ?? 0) - (a.relevanceScore ?? 0));

      state.errors = action.payload.errors;
      state.isLoading = false;
    },
    addConnectedWallet: (state, action: PayloadAction<string>) => {
      const walletAddress = action.payload.toLowerCase();
      if (!state.connectedWallets.includes(walletAddress)) {
        state.connectedWallets.push(walletAddress);
      }
    },
    removeConnectedWallet: (state, action: PayloadAction<string>) => {
      const walletAddress = action.payload.toLowerCase();
      state.connectedWallets = state.connectedWallets.filter(
        (address) => address !== walletAddress
      );
    },
    clearWalletBalances: (state, action: PayloadAction<string>) => {
      const disconnectedAddress = action.payload.trim().toLowerCase();
      state.balances = state.balances?.filter(
        (balance) =>
          balance.wallet_address?.toLowerCase() !== disconnectedAddress
      );
      state.connectedWallets = state.connectedWallets.filter(
        (address) => address !== disconnectedAddress
      );
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateTokenConfetti: (state, action) => {
      state.destinationToken = action.payload;
    },
    setNfts: (state, action: PayloadAction<NFT[]>) => {
      state.nfts = action.payload;
    },
    setChatHistory: (
      state,
      action: PayloadAction<{
        [chatId: string]: {
          messages: {
            message: string;
            agent: string;
            data?: any | null;
            component?: string | null;
          }[];
          lastUpdatedAt?: string;
          associated_wallet: string;
        };
      }>
    ) => {
      if (!state.chatHistory) {
        state.chatHistory = {};
      }
      for (const [chatId, chatData] of Object.entries(action.payload)) {
        if (!state.chatHistory[chatId]) {
          state.chatHistory[chatId] = {
            messages: [],
            lastUpdatedAt: chatData.lastUpdatedAt || new Date().toISOString(),
            associated_wallet: chatData.associated_wallet,
          };
        }
        state.chatHistory[chatId].messages.push(...chatData.messages);
        state.chatHistory[chatId].lastUpdatedAt =
          chatData.lastUpdatedAt || new Date().toISOString();
        state.chatHistory[chatId].associated_wallet =
          chatData.associated_wallet;
      }

      state.chatHistory = Object.fromEntries(
        Object.entries(state.chatHistory).sort((a, b) => {
          const dateA = new Date(b[1].lastUpdatedAt).getTime();
          const dateB = new Date(a[1].lastUpdatedAt).getTime();
          return dateA - dateB;
        })
      );
    },
    addMessageToChatHistory: (
      state,
      action: PayloadAction<{
        currentChatId: string | null;
        message: string;
        agent: string;
        data?: any | null;
        component?: string | null;
      }>
    ) => {
      let { currentChatId, message, agent, data, component } = action.payload;

      if (!state.chatHistory) {
        state.chatHistory = {};
      }

      const timestamp = new Date().toISOString();

      // if currentChatId is null, check if the user state has a currentChatId
      if (currentChatId === null && state.currentChatId) {
        currentChatId = state.currentChatId;
      }

      if (currentChatId) {
        if (!state.chatHistory[currentChatId]) {
          state.chatHistory[currentChatId] = {
            messages: [],
            lastUpdatedAt: timestamp,
          };
        }
        state.chatHistory[currentChatId].messages.push({
          message,
          agent,
          data,
          component,
        });
        state.chatHistory[currentChatId].lastUpdatedAt = timestamp;
      } else {
        const newChatId = uuidv7();
        state.chatHistory[newChatId] = {
          messages: [{ message, agent, data, component }],
          lastUpdatedAt: timestamp,
          associated_wallet: null, // Temporary, we udpate it when the server sets the chat id
        };
        state.currentChatId = newChatId;
      }

      // Sort chatHistory by lastUpdatedAt from most recent to oldest
      state.chatHistory = Object.fromEntries(
        Object.entries(state.chatHistory).sort((a, b) => {
          const dateA = new Date(b[1].lastUpdatedAt).getTime();
          const dateB = new Date(a[1].lastUpdatedAt).getTime();
          return dateA - dateB;
        })
      );
    },
    // Remove Chats History based on the wallet address (when disconnecting a wallet, we remove those chats from the state)
    removeChatHistory: (state, action: PayloadAction<string>) => {
      const walletAddress = action.payload.trim().toLowerCase();
      if (state.chatHistory) {
        state.chatHistory = Object.fromEntries(
          Object.entries(state.chatHistory).filter(
            ([_, chat]) =>
              chat.associated_wallet?.toLowerCase() !== walletAddress
          )
        );
      }
    },
    deleteChat: (state, action: PayloadAction<string>) => {
      const chatId = action.payload;
      if (state.chatHistory) {
        delete state.chatHistory[chatId];
      }
    },
    setCurrentChatId: (state, action: PayloadAction<string | null>) => {
      state.currentChatId = action.payload;
    },
    setNewChatId: (state, action: PayloadAction<string>) => {
      // Updates the chatId of the 'new0 chat added to the local chatHistory
      // With the real chatId generated on the server
      if (state.chatHistory && state.currentChatId) {
        const newChatId = action.payload;
        const currentChat = state.chatHistory[state.currentChatId];
        delete state.chatHistory[state.currentChatId];
        state.chatHistory = { [newChatId]: currentChat, ...state.chatHistory };
        state.currentChatId = newChatId;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountryCode.fulfilled, (state, action) => {
      state.countryCode = action.payload || "US";
    });
    builder.addCase(getCountryCode.rejected, (state) => {
      state.countryCode = "US";
    });
  },
});

export const {
  getBalances,
  updateBalancesAfterTransaction,
  updateTokenConfetti,
  setIsLoading,
  clearWalletBalances,
  addConnectedWallet,
  removeConnectedWallet,
  setNfts,
  setChatHistory,
  addMessageToChatHistory,
  removeChatHistory,
  deleteChat,
  setCurrentChatId,
  setNewChatId,
} = userSlice.actions;
export default userSlice.reducer;
